import {Component} from '@angular/core';
import { environment } from '../environments/environment';
import { Gtag } from 'angular-gtag';
// import {trigger, state, animate, style, transition, query, group} from '@angular/animations';

@Component({
  selector: 'app-root',
  template:
  // `
  // <h1>Universal Demo using Angular and Angular CLI</h1>
  // <a routerLink="/">Home</a>
  // <a routerLink="/lazy">Lazy</a>
  // <a routerLink="/lazy/nested">Lazy_Nested</a>
  `
  <router-outlet></router-outlet>
  `,
  styles: []
})
export class AppComponent {

  constructor(gtag: Gtag) { }
}
