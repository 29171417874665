import {Component, OnInit} from '@angular/core';
import { trigger, animate, style, group, animateChild, query, stagger, transition } from '@angular/animations';

export const fadeInAnimation = trigger('fadeInAnimation', [

  // route 'enter' transition
  transition(':enter', [

    // css styles at start of transition
    style({ opacity: 0 }),

    // animation and styles at end of transition
    animate('.3s', style({ opacity: 1 }))
  ]),
]);

@Component({
  selector: 'app-home',
  templateUrl: 'home.html',
  animations: [fadeInAnimation],
  styleUrls: [
    'home.scss'
  ]
})
export class HomeComponent implements OnInit {
  public message: string;

  constructor(
  ) {}

  ngOnInit() {
    this.message = 'Hello';
    console.log('test');
  }
}
