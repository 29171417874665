<div class="container" fxLayoutAlign="center center" fxLayout="column">
    <div fxFlex="0 0 auto" class="content" fxLayout.gt-sm="row" fxLayout="column">
        <div fxLayout="column" fxFlex="0 0 auto" fxFlex.gt-sm="0 0 220px" class="container-margin u-flex-fix">
            <div fxFlex="0 0 auto" fxLayoutAlign="start center" class="img">
                <img src="../../assets/images/user.webp" width="100%" alt="Justin Parra Image" />
            </div>

            <div fxFlex="0 0 5px"></div>

            <div fxFlex="0 0 auto" fxLayoutAlign="start center" fxLayout="row wrap" class="name-text">
                <span>Justin</span>
                <span>Ruben</span>
                <span>Parra</span>
            </div>

            <div fxFlex="0 0 20px"></div>

            <div fxFlex="0 0 auto" fxLayoutAlign="start center" fxLayout="row">
                <a href="tel:+15127669027" gtagEvent trackOn="click" category="contact" [params]="{ event_label: '512 phone number' }">+1 512 766 9027</a>
            </div>

            <div fxFlex="0 0 5px"></div>

            <div fxFlex="0 0 auto" fxLayoutAlign="start center" fxLayout="row">
                <a href="tel:+19158207620" gtagEvent trackOn="click" category="contact" [params]="{ event_label: '915 phone number' }">+1 915 820 7620</a>
            </div>

            <div fxFlex="0 0 15px"></div>

            <div fxFlex="0 0 auto">
                El Paso Texas, United States
            </div>

            <div fxFlex="0 0 15px"></div>

            <div fxFlex="0 0 auto" fxLayoutAlign="start center" fxLayout="row">
                <a href="mailto:contact@parrajust.in" gtagEvent trackOn="click" category="contact" [params]="{ event_label: 'email link' }">contact@parrajust.in</a>
            </div>

            <div fxFlex="0 0 20px" style="display: none;"></div>

            <div fxFlex="0 0 auto" style="display: none;">
                <a routerLink="/blog">
                    <i class="fas fa-arrow-left"></i> Blog</a>
            </div>
        </div>

        <div fxFlex.gt-sm="1 1 100%" fxFlex="0 0 auto" fxLayout="column" class="container-margin" @fadeInAnimation>
            <div fxFlex="0 0 20px"></div>

            <div fxFlex="0 0 auto" fxLayout="column">
                <div fxFlex="0 0 auto" class="division-title">
                    <i class="fas fa-graduation-cap"></i> Education
                </div>

                <div fxFlex="0 0 auto" class="division-group" fxLayout="column">
                    <div fxFLex="0 0 auto" class="division-subgroup-title">
                        <a href="https://www.utep.edu/">University of Texas at El Paso</a>
                        <span class="sub"> — fall 2015 to spring 2018</span>
                    </div>

                    <div fxFlex="0 0 auto" class="division-subgroup-content">
                        <span class="bold">Bachelors of Science</span> in
                        <a href="http://www.cs.utep.edu/">Computer Science</a> with a
                        <span class="bold">minor</span> in
                        <a href="https://www.utep.edu/science/math/">Mathematics</a>.
                    </div>

                    <div fxFlex="0 0 auto" class="division-subgroup-content">
                        Graduated with a Major
                        <span class="bold">GPA 3.8 / 4.0</span>, Magna Cum Laude.
                    </div>

                    <div fxFlex="0 0 auto" class="division-subgroup-content">
                        Membership in groups:
                        <a href="https://www.ieee.org/">IEEE</a>,
                        <a href="http://mymaes.org/">MAES</a>/
                        <a href="http://www.shpe.org/">SHPE</a>,
                        <a href="http://orderofomega.org/">Order of Omega</a>,
                        <a href="https://www.thetachi.org/">ΘΧ</a>
                    </div>
                </div>
            </div>

            <div fxFlex="0 0 20px"></div>

            <div fxFlex="0 0 auto" fxLayout="column">
                <div fxFlex="0 0 auto" class="division-title">
                    <i class="fas fa-user-plus"></i> Experiance
                </div>

                <div fxFlex="0 0 auto" class="division-group" fxLayout="column">
                    <div fxFLex="0 0 auto" class="division-subgroup-title">
                        <a href="https://www.utep.edu/">University of Texas at El Paso</a> Undergrad Research Assistant
                        <span class="sub"> — summer 2016 to spring 2018</span>
                    </div>

                    <div fxFlex="0 0 auto" class="division-subgroup-content">
                        Worked closely with professors from the department of computer science on a project whose goal is to improve upon current
                        methods of predicting volcanic behavior, using Recurrent Neural Networks.
                    </div>

                    <div fxFlex="0 0 2px"></div>

                    <div fxFlex="0 0 auto" class="division-subgroup-content">
                        Created a three-dimensional viewer for seismic events relating to volcanic eruptions. The tool helped the team visualize
                        events leading up to a volcanic eruption. The tool was created using the open source Electron framework.
                    </div>

                    <div fxFlex="0 0 10px"></div>

                    <div fxFlex="0 0 auto" class="division-subgroup-content">
                        <div class="bold">publications:</div>
                    </div>

                    <div fxFlex="0 0 auto" class="division-subgroup-content" fxLayout="row">
                        <div fxFlex="0 0 15px"></div>
                        <div class="publication">- Parra, Justin;
                            <a href="http://www.cs.utep.edu/ofuentes/">Fuentes, Olac</a>;
                            <a href="https://academics.utep.edu/Default.aspx?tabid=70104&amp;ID=eanthony">Anthony, Elizabeth Y.</a>; and
                            <a href="http://www.cs.utep.edu/vladik/">Kreinovich, Vladik</a>,
                            <a href="https://digitalcommons.utep.edu/cgi/viewcontent.cgi?referer=https://www.google.com/&httpsredir=1&article=2130&context=cs_techrep">"Prediction of Volcanic Eruptions as a Case Study of Predicting Rare Events in Chaotic Systems
                                with Delay"</a> (2017). Departmental Technical Reports (CS). 1132.</div>
                    </div>

                    <div fxFlex="0 0 auto" class="division-subgroup-content" fxLayout="row">
                        <div fxFlex="0 0 15px"></div>
                        <div class="publication">- Parra, Justin;
                            <a href="http://www.cs.utep.edu/ofuentes/">Fuentes, Olac</a>;
                            <a href="https://academics.utep.edu/Default.aspx?tabid=70104&amp;ID=eanthony">Anthony, Elizabeth Y.</a>; and
                            <a href="http://www.cs.utep.edu/vladik/">Kreinovich, Vladik</a>,
                            <a href="https://digitalcommons.utep.edu/cgi/viewcontent.cgi?article=2070&context=cs_techrep">"Use of Machine Learning to Analyze and -- Hopefully -- Predict Volcano Activity"</a> (2016).
                            Departmental Technical Reports (CS). Paper 1053</div>
                    </div>
                </div>

                <div fxFlex="0 0 auto" class="division-group" fxLayout="column">
                    <div fxFLex="0 0 auto" class="division-subgroup-title">
                        <a href="https://www.att.com/">AT&T</a> Student co-op Technical I
                        <span class="sub"> — spring 2017 to spring 2018</span>
                    </div>

                    <div fxFlex="0 0 auto" class="division-subgroup-content">
                        Worked in the AT&T co-op center in a continuous learning environment while attending
                        <a href="https://www.utep.edu/">UTEP</a> full time.
                    </div>

                    <div fxFlex="0 0 2px"></div>

                    <div fxFlex="0 0 auto" class="division-subgroup-content">
                        Created a variety of web applications to solve the needs of several internal AT&T divisions, by following an agile process that stressed rapid prototyping and customer feedback.
                    </div>

                    <div fxFlex="0 0 5px"></div>

                    <div fxFlex="0 0 auto" class="division-subgroup-content" fxLayout="row">
                        <a routerLink="/blog/wireline" fxFlex="0 0 auto" fxLayout="row" fxLayoutGap="5px">
                            <div class="bold">Wireline-RF Microwave Site</div>
                            <i class="fas fa-external-link"></i>
                        </a>
                    </div>

                    <div fxFlex="0 0 auto" class="division-subgroup-content">
                        Orchestrated the total conversions of a Microsoft CSV website into a modern, reactive website. Lead a team of coops through
                        the software development process of the site that provides the means for creating, removing, and
                        updating the pertinent data relating to the operations of microwave sites across the United States
                        by AT&T. Provided a restful application programming interface that provides for expandability through
                        the use of bots. The solution was created with the following technologies:
                        <a href="https://angular.io/">Angular 5</a>,
                        <a href="https://expressjs.com/">Express</a>,
                        <a href="https://code.visualstudio.com/">Vs code</a>,
                        <a href="https://nodejs.org/en/">NodeJS</a>, and
                        <a href="https://www.microsoft.com/en-us/sql-server/default.aspx">Microsoft SQL</a>.
                    </div>

                    <div fxFlex="0 0 5px"></div>

                    <div fxFlex="0 0 auto" class="division-subgroup-content" fxLayout="row">
                        <a routerLink="/blog/awoc" fxFlex="0 0 auto" fxLayout="row" fxLayoutGap="5px">
                            <div class="bold">AT&T Weather Operations Site</div>
                            <i class="fas fa-external-link"></i>
                        </a>
                    </div>

                    <div fxFlex="0 0 auto" class="division-subgroup-content">
                        Continued work on an internal AT&T weather information site. The site combined feeds from a variety of sources which are
                        used to provide forecast and current weather information for internal AT&T employees. Sources included,
                        but not limited to, Satellite, Radar, Accu-weather Enterprise Services, and internal Microsoft SQL
                        database feeds. The website employed the
                        <a href="https://angularjs.org/">AngularJS</a> framework,
                        <a href="https://www.asp.net/">ASP .NET</a> backend,
                        <a href="https://www.visualstudio.com/vs/community/">Visual Studio</a>, and
                        <a href="https://www.microsoft.com/en-us/sql-server/default.aspx">Microsoft SQL</a>.
                    </div>

                    <div fxFlex="0 0 5px"></div>

                    <div fxFlex="0 0 auto" class="division-subgroup-content" fxLayout="row">
                        <div class="bold">Interactive Network Element Tool</div>
                    </div>

                    <div fxFlex="0 0 auto" class="division-subgroup-content">
                        Web tool which provides AT&T customers with the ability to manage and customize legacy network elements, which relate to
                        servers and network switches. The tool provides for the ability to search billions of elements using
                        advanced search queries. The tool also provides an interactive model of a customers network graph.
                        The website is built with
                        <a href="https://angularjs.org/">AngularJS</a> framework,
                        <a href="https://www.asp.net/">ASP .NET</a> backend,
                        <a href="https://www.visualstudio.com/vs/community/">Visual Studio</a>, and
                        <a href="https://www.microsoft.com/en-us/sql-server/default.aspx">Microsoft SQL</a>.
                    </div>

                    <div fxFlex="0 0 5px"></div>

                    <div fxFlex="0 0 auto" class="division-subgroup-content" fxLayout="row">
                        <div class="bold">CIQ Automation</div>
                    </div>

                    <div fxFlex="0 0 auto" class="division-subgroup-content">
                        Worked with AT&T engineers to create a tool that automates the process the engineers go through daily. Decrease worker time
                        creating spec sheets and other required documents. The tool was built with
                        <a
                            href="https://www.sap.com/index.html">Sap IQ Database</a>,
                        <a href="https://www.oracle.com/database/index.html">Oracle Database</a>,
                        <a href="https://products.office.com/en-us/sharepoint/collaboration">Sharepoint</a>, and
                        <a href="https://www.microsoft.com/en-us/sql-server/default.aspx">Microsoft SQL</a>.
                    </div>

                    <div fxFlex="0 0 5px"></div>

                    <div fxFlex="0 0 auto" class="division-subgroup-content" fxLayout="row">
                        <div class="bold">Week-over-week Project Managment Tool</div>
                    </div>

                    <div fxFlex="0 0 auto" class="division-subgroup-content">
                        AT&T internal monitoring tool to check on the progress of co-op workers. Provides an interface for managment to check up
                        on weekly progress and accomplishments of it's employees. Worked with other co-ops to optmize website
                        data access and storage, as well and modernizing the interface to improve user experiance. The website
                        is built with
                        <a href="https://www.adobe.com/products/coldfusion-family.html">Cold fusion</a>, and
                        <a href="https://www.microsoft.com/en-us/sql-server/default.aspx">Microsoft SQL</a>.
                    </div>
                </div>
            </div>

            <div fxFlex="0 0 20px"></div>

            <div fxFlex="0 0 auto" fxLayout="column">
                <div fxFlex="0 0 auto" class="division-title">
                    <i class="fas fa-plus-octagon"></i> Additional Experience, Leadership, and Volunteer Activities
                </div>

                <div fxFlex="0 0 auto" class="division-group" fxLayout="column">
                    <div fxFLex="0 0 auto" class="division-subgroup-title">
                        <a href="https://www.hselpaso.org/">El Paso Humane Society </a> Animal Handler
                        <span class="sub"> — 2+ years</span>
                    </div>

                    <div fxFlex="0 0 5px"></div>

                    <div fxFLex="0 0 auto" class="division-subgroup-title">
                        <a href="https://elpasoansfightinghunger.org/">El Pasoans Fighting Hunger Food Bank</a> Volunteer
                    </div>

                    <div fxFlex="0 0 5px"></div>

                    <div fxFLex="0 0 auto" class="division-subgroup-title">
                        <a href="https://www.faa.gov/licenses_certificates/airmen_certification/student_replacement/">FAA Stuent Pilot</a> - Private Pilot
                    </div>

                    <div fxFlex="0 0 5px"></div>

                    <div fxFLex="0 0 auto" class="division-subgroup-title">
                        El Paso Aspire Mentoring Academy program
                        <span class="sub"> — Mult-year volunteer and presentor</span>
                    </div>
                </div>
            </div>

            <div fxFlex="0 0 20px"></div>

            <div fxFlex="0 0 auto" fxLayout="column">
                <div fxFlex="0 0 auto" class="division-title">
                    <i class="fas fa-briefcase"></i> Projects
                </div>

                <div fxFlex="0 0 auto" class="division-group" fxLayout="column">
                    <div fxFLex="0 0 auto" class="division-subgroup-title">
                        Seismic event visualizer
                    </div>

                    <div fxFlex="0 0 auto" class="division-subgroup-content">
                        A research project where I used both
                        <a href="https://threejs.org/">Three.JS</a> and
                        <a href="https://electronjs.org/">Electron</a> to create a 3d visualization of the
                        <a href="https://www.volcanodiscovery.com/aleutians.html">volcanoes of the Aleutian islands</a>.
                    </div>

                    <div fxFlex="0 0 2px"></div>

                    <div fxFlex="0 0 auto" class="division-subgroup-content">
                        The project assisted in the early part of the research project where we applied cluster analysis to find ways to catagorize
                        the seismic events under volcanoes.
                    </div>
                </div>

                <div fxFlex="0 0 auto" class="division-group" fxLayout="column">
                    <div fxFLex="0 0 auto" class="division-subgroup-title">
                        <a href="https://github.com/codexa/firetext">FireText</a>
                    </div>

                    <div fxFlex="0 0 auto" class="division-subgroup-content">
                        Ported over the app from being based on the fire fox operating system to being one that runs on the
                        <a
                            href="https://electronjs.org/">Electron</a> framework.
                    </div>
                </div>
            </div>

            <!-- <div fxFlex="0 0 20px"></div>

            <div fxFlex="0 0 auto" fxLayout="column">
                <div fxFlex="0 0 auto" class="division-title">
                    Languages and Technologies
                </div>

                <div fxFlex="0 0 auto" class="division-group" fxLayout="column">
                    <div fxFLex="0 0 auto" class="division-subgroup-title">
                        <a href="https://www.hselpaso.org/">El Paso Humane Society </a> Animal Handler<span class="sub"> — 2+ years</span>
                    </div>

                    <div fxFlex="0 0 5px"></div>

                    <div fxFLex="0 0 auto" class="division-subgroup-title">
                        <a href="https://elpasoansfightinghunger.org/">El Pasoans Fighting Hunger Food Bank</a> Volunteer
                    </div>

                    <div fxFlex="0 0 5px"></div>

                    <div fxFLex="0 0 auto" class="division-subgroup-title">
                        <a href="https://www.faa.gov/licenses_certificates/airmen_certification/student_replacement/">Faa Stuent Pilot</a> - Private Pilot
                    </div>

                    <div fxFlex="0 0 5px"></div>

                    <div fxFLex="0 0 auto" class="division-subgroup-title">
                        El Paso Aspire Mentoring Academy program<span class="sub"> — Mult-time volunteer and presentor</span>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</div>

<div class="footer" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="30px">
    <div fxFlex="0 0 auto" fxLayoutAlign="center start" fxLayout="column" fxLayout.gt-xs="row">
        <div fxFlex="0 0 20px"></div>

        <div fxFlex="0 0 auto" fxLayoutAlign="center center" fxLayout="column" fxLayoutGap="5px">
            <div fxFlex="0 0 auto">
                <h2>Site Map</h2>
            </div>

            <div fxFlex="0 0 auto">
                HOME
            </div>

            <div fxFlex="0 0 auto">
                <s>BLOG</s>
            </div>
        </div>

        <div fxFlex="0 0 15px"></div>

        <div fxFlex="0 0 auto" fxLayoutAlign="center center" fxLayout="column">
            <div fxFlex="0 0 auto">
                <h2>Contact</h2>
            </div>

            <div fxFlex="0 0 auto">
                <a href="mailto:contact@parrajust.in">Email</a>
            </div>
        </div>

        <div fxFlex="0 0 20px"></div>
    </div>

    <div fxFlex="0 0 auto" fxLayoutAlign="center center">
        Justin Ruben Parra © 2018.
    </div>
</div>